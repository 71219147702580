$svg-char-angle-down: '<svg viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg"><path d="M1395 736q0 13-10 23l-466 466q-10 10-23 10t-23-10l-466-466q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l393 393 393-393q10-10 23-10t23 10l50 50q10 10 10 23z"/></svg>';

.logo-maintenance_wrapper {
    padding: 50px 15px;
}
.error-actions {
    margin-bottom: 25px;
}
.order_header {
    align-items: flex-end;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    div.group_date_picker {
        display: flex;
        & > div {
            margin: 0 15px;
        }
    }
    & > div {
        margin-bottom: 10px;
        margin-right: 15px;
        &:last-child {
            flex-grow: 1;
            margin-right: 0;
        }
    }
    .react-datepicker-popper {
        z-index: 11;
    }
}
button.react-datepicker__close-icon::after {
    background-color: $iron;
}
#wrapper-full {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 112px);
    #wrapper-left {
        max-width: 112px;

        // position:fixed
        #left-sidebar {
            position: sticky;
            top: 16px;

            // color:white;
            background-color: white;

            min-height: 70vh;
            border-radius: 16px;
            margin: 16px 0px 16px 16px; //preguntar a jota
            padding: 8px;
            font-size: 12px;
            @media all and (max-width: 767px) {
                        .menu{
                            position: absolute;
                width: 100%;
                bottom: 72px;
                left:0;
                background: white;
                .navbar{
                    flex-direction: row;
                    flex-wrap:wrap;
                    justify-content: space-evenly;
    align-items: center;
               .navbar-nav {
                    flex-direction: row;
                    flex-wrap:wrap;
                    justify-content: space-evenly;
    align-items: center;
               }
    .nav-link{
        width:96px;
    }
                }
            }
        }
            img {
                width: 100%;
            }
            .navbar {
                // flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                flex-direction:column;
                .navbar-nav {
                    flex-direction: column;
                    a,
                    .nav-link {
                        display: flex;
                        flex-direction: column;
                        color: $onyx;
                    }
                    .dropdown-toggle::after {
                        display: none;
                    }
                    // flex-direction: column;
                }
            }
            @media all and (max-width: 767px) {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                position: fixed;
                bottom: 0;
                top: initial;
                height: 80px;
                min-height: 80px;
                margin: 0;
                padding: 16px;
                width:100%;
                z-index: $layer20;
                border-radius: 16px 16px 0 0;
                img{
                    max-width: 200px;
                }
                // margin-right:16px;
               
                    .navbar {
                        flex-direction: row !important;
                    }
                
            }
        }
    }
    #wrapper-right {
        flex-grow: 1;
        // margin: 0px 16px;
        padding:0px 16px;
        width:calc(100% - 144px);
          @media (max-width: $smMaxWidth) {
            width:100%;
        }
    }
    #wrapper-contain {
        flex-grow: 1;
        min-height:50vh;
        // margin:0 16px 0 16px;
        .container-fluid {
            background-color: white;
            border-radius: 16px;
            margin: 16px 0;
            padding: 16px 32px;
            // padding-right: 0 !important;
            // padding-top: 0 !important;
            // padding-bottom: 0 !important;
            
            @media (max-width: $mdMaxWidth) {
                margin: 8px 0;
                padding:8px 12px;
            }
        }
    }
}
.img-line-product {
    text-align: center;
    img.img-fluid {
        max-height: 100px;
    }
}
.col-centered {
    float: none;
    margin: 0 auto;
}
.col-hidden {
    display: none;
}
.txt-centered {
    text-align: center;
}
.no-padding {
    padding: 0;
}
.flag-icon {
    //background-size: cover;
    border: 1px solid $gray;
    //margin-right: 10px;
    //border-radius: 50%;
    //display: inline-block;
    //height: 30px;
    //overflow: hidden;
    //vertical-align: middle;
    //width: 16px !important;
}

.btn-cerrar {
    background-color: $white;
    border: 1px solid $iron;

    border-radius: 50%;
    color: $gray;
    font-size: 13px;
    height: 2.8em; //40px;
    line-height: 2.7;
    overflow: hidden;
    padding: 0;
    text-align: center;
    text-indent: -9999px;
    width: 2.8em; //40px;

    &:hover {
        background-color: $onyx;
        color: $white;
    }

    &:before {
        content: '\2715';
        display: block;
        text-indent: 0;
    }
}

.loading-bar {
    background: $headerBackground;
    height: 4px;
    z-index: $layer60;
    position: fixed;
    top: 0;
    // padding-top:2px;
    // margin-top:2px;
}
.full-overlay {
    background: rgba($thunder, 0.65);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: $layer40;
}

.modal-overlay {
    background: rgba($thunder, 0.65);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: $headerHeight + 16px;
    width: 100%;
    z-index: $layer10;
    left: 128px;
    border-radius: 8px;
    @media (max-width: $mdMaxWidth) {
        visibility: hidden;
    }
}
.modal {
    .mall-discount {
        .modal-header,
        .modal-footer {
            border: none;
        }
    }
    .modal-dialog {
        .modal-content {
            max-height: calc(100vh - 64px);
            .modal-header {
                .close {
                    cursor: pointer;
                    margin-left: auto;
                }
            }
            .modal-body {
                overflow-y: auto;
            }
        }
        &.showOverflow {
            .modal-content {
                .modal-body {
                    overflow: visible;
                }
            }
        }
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}
.blur-overflow {
    background-size: cover;
    overflow: hidden;
}
.fit-image {
    margin: 0 auto;
    overflow: hidden;
    width: fit-content;
}

.ReactModal__Overlay {
    z-index: $layer30 !important;
}

#confirm-container {
    background: white;

    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
    left: 0;
    margin: auto;
    max-width: 650px;
    position: fixed;
    right: 0;
    top: 20%;
    width: 100%;
    z-index: 20000;

    @media (max-width: $mdMaxWidth) {
        bottom: 30%;
        left: 0;
        -webkit-overflow-scrolling: touch;
        overflow-x: none;
        overflow-y: scroll;
        top: unset;
        width: 100%;
    }
}
.confirm {
    header {
        padding: 20px 25px;

        &:hover {
            color: $onyx;
        }

        i {
            font-size: 40px;
        }
    }
    h2 {
        padding-bottom: 40px;
        @media (max-width: $mdMaxWidth) {
            font-size: 1.5rem;
            padding-bottom: 20px;
        }
    }
}

.alert {
    font-size: 14px;
    padding: 5px 10px;
}
#username {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 30ch;
}
.tooltip {
    display: inline-block;
    opacity: 1;
    position: relative;
    z-index: auto;
}

/* Tooltip text */
.tooltip .tooltiptext {
    background-color: #555;
    border-radius: 6px;
    bottom: 125%;
    color: #fff;
    left: 50%;
    margin-left: -60px;

    /* Fade in tooltip */
    opacity: 0;
    padding: 5px 0;

    /* Position the tooltip text */
    position: absolute;
    text-align: center;
    transition: opacity 0.3s;
    visibility: hidden;
    width: 120px;
    z-index: 100;
}
/* Tooltip arrow */
.tooltip .tooltiptext::after {
    border-color: #555 transparent transparent transparent;
    border-style: solid;
    border-width: 5px;
    content: '';
    left: 50%;
    margin-left: -5px;
    position: absolute;
    top: 100%;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    opacity: 1;
    visibility: visible;
    z-index: $layer60;
}

.error-template {
    text-align: center;
}
@media (min-width: 768px) {
    .error-template {
        align-items: center;
        display: flex;
        height: calc(100vh - 346px);
        justify-content: center;
        div.bt {
            margin-top: 5px;
        }
    }
}

section {
    min-height: calc(100vh - 300px);
    padding-bottom: 40px;
}
.box {
    background: #ccc;
    border: 1px solid black;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    overflow: hidden;
    position: relative;
    margin: 20px;
}
.product-title {
    font-family: $titleFont;
    text-transform: capitalize;
}
.badge-primary {
    background: rgba(26, 26, 26, 0.85);
}
.badge-secondary {
    background: #ff5555;
    color: white;
}
.badge-property{
    background:#F6F6F4;
    span{
color: #232323;
font-weight: normal;
    }
    
}
.tip {
    margin-left: 5px;
    font-size: 12px;
}
.red-tip {
    color: red;
}
.displayNone {
    display: none;
}
.ReactTable {
    .rt-tr {
        transition: 0.5s;
        &.edited {
            border: 1px solid lightgray;
        }
        &.reject {
            overflow: hidden;
            position: relative;
            &:after {
                background-color: rgba(150, 150, 150, 0.2);
                content: '';
                height: 100%;
                position: absolute;
                width: 100%;
            }
        }
    }
}

[dir='rtl'] {
    body {
        text-align: right;
    }
}

.border {
    border-color: #ddd !important;
}

$table-header: #07887d;
$table-header-border: #07695e;
$table-border: #d9d9d9;
$row-bg: #f4f2f1;

.table-container {
    display: block;

    .flex-table {
        display: flex;
        flex-flow: row wrap;
        border-left: solid 1px $table-border;
        transition: 0.5s;

        &:first-of-type {
            border-top: solid 1px $table-header-border;
            border-left: solid 1px $table-header-border;
        }

        &:first-of-type .flex-row {
            background: $table-header;
            color: white;
            border-color: $table-header-border;
        }

        &.row:nth-child(odd) .flex-row {
            background: $row-bg;
        }

        &:hover {
            background: #f5f5f5;
            transition: 500ms;
        }
    }

    .flex-row {
        width: calc(100% / 5);
        text-align: center;
        padding: 0.5em 0.5em;
        border-right: solid 1px $table-border;
        border-bottom: solid 1px $table-border;
    }

    @media all and (max-width: 767px) {
        .flex-row {
            width: calc(100% / 4);

            &.first {
                width: 100%;
            }
        }
    }

    @media all and (max-width: 430px) {
        .flex-table {
            .flex-row {
                border-bottom: 0;
            }

            .flex-row:last-of-type {
                border-bottom: solid 1px $table-border;
            }
        }

        .header {
            .flex-row {
                border-bottom: solid 1px;
            }
        }

        .flex-row {
            width: 100%;

            &.first {
                width: 100%;
                border-bottom: solid 1px $table-border;
            }
        }
    }
}

.sk-search-box__action, .sk-input-filter__action {
    display: none;
}
.image {
    .__react_component_tooltip {
        padding: 0 !important;
        margin: 0;
    }
}

.text-right {
    text-align: right !important;
}
.nav-link > a {
    color: inherit;
}

.alert {
    color: #000000 !important;
    font-weight: 400 !important;
    border-radius: 8px !important;
    padding: 8px 16px !important;
}
.alert-danger {
    background-color: $error !important;
    border: none !important;
}
.alert-warning {
    background-color: $primary !important;
    border: none !important;
}
.alert-success {
    background-color: $success !important;
    border: none !important;
}
    .circle {
//   background-color: #ccc;
  border-radius: 50%;
  height:40px;
  text-align: center;
  width: 40px;
  min-width:40px;
  display:flex;
  align-items: center;
  justify-content: center;
  border:1px solid black;
  background:$primary;
  color: #101823;
}

.initials {
  font-size: calc(40px / 2); /* 50% of parent */
  line-height: 1;
//   position: relative;
//   top: calc(40px / 4); /* 25% of parent */
}
.sk-select select, .sk-select{
    outline: 0 !important;
    border:none !important;
    background:transparent !important;
}

.border-left-line {
    height:40px;
    text-align: center;
    min-width:40px;
    display:flex;
    align-items: center;
    justify-content: center;
    border-left:1px solid black;
    color: #101823;
}



.home-cards{
    background:white;
    border-radius:16px;
    .home-card{
    display:flex;
    flex-direction:column;
    justify-content:start;
    align-items:center;
    background:white;
    border-radius:16px;
    padding:32px 16px;
        p{
            color:$gray;
        }
    }
    
    // margin:16px;
        display: grid;
grid-gap:16px;
            grid-template-columns: repeat(4,1fr);
            margin:16px 0;
            @media (max-width: $mdMaxWidth) {
                grid-template-columns: repeat(1, 1fr);
            }

}
.nav-link{

        color:$onyx !important;
}

.filter-white{
    filter: invert(100%) saturate(2476%) hue-rotate(86deg) brightness(118%) contrast(119%);
}

.badge.badge-professional{
    border-radius: 10px;
    padding: 4px 8px;
    max-width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer {
    @media all and (max-width: 767px) {
        padding-bottom: 80px;
    }
}

.flag-icon-2x{
    font-size: 1.5em !important;
}
.checkout-summary{
    flex-direction:column;
    @media (min-width: $mdMaxWidth) {
        flex-direction: row;

    }
}

.search-box-container {

    .sk-search-box__icon{
        top:5px;
    }
    .sk-search-box__text{
        border-radius: 4px !important;
        padding:5px 16px 5px 40px !important;
        width:100%;
    }
            .sk-search-box__icon {
            position: relative;
            width: 100%;
            font-size: 21px;
            &:before {
                color: $onyx;
                content: '\f002';
                font-family: 'Font Awesome 5 Pro';
                left: 16px;
                position: absolute;
                width: 0;
                height: 0;
                [dir='rtl'] & {
                    left: unset;
                    right: 12px;
                }
            }
        }
}
.form-check{
    label{
        margin-left:8px;
    }
}

.grid-switch{
    .bt{
       &:first-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
       }
       &:last-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
       }
       }

}